import React, { useState,createContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc, doc, getDoc, setDoc  } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged  } from 'firebase/auth';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const firebaseConfig = {
    apiKey: "AIzaSyAYLo7hrQgoATgbEKCn7dxAHvP9dElPXJ0",
    authDomain: "animetier-563ff.firebaseapp.com",
    projectId: "animetier-563ff",
    storageBucket: "animetier-563ff.appspot.com",
    messagingSenderId: "196586641001",
    appId: "1:196586641001:web:194b8b30de874115de34f8",
    measurementId: "G-9CY563QGJ9"
};

const app = initializeApp(firebaseConfig);




function Login() {
    const [state, setState] = useState([]);
    const [mail, setMail] = useState();
    const [password, setPassword] = useState();
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);

  useEffect(() => {
    const AuthContext = createContext();
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        // L'utilisateur est connecté
        setUser(authUser);
        // Récupérer ou créer les données utilisateur dans Firestore
        const db = getFirestore();
        const userDocRef = doc(db, 'users', authUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          // Les données utilisateur existent déjà dans Firestore
          setUserData(userDocSnap.data());
        } else {
          // Les données utilisateur n'existent pas encore dans Firestore, les créer
          await setDoc(userDocRef, { /* Données utilisateur initiales */ });
          setUserData({ /* Données utilisateur initiales */ });
        }
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      } else {
        // L'utilisateur n'est pas connecté
        setUser(false);
        setUserData(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const authenticateUser = async (email, password) => {
    try {
        setUser('wait');
        setUserData('wait');
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error('Erreur lors de l\'authentification:', error);
      setUser(false);
      setUserData(false);
    }
  };

    return (
        <Grid sx={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
            <Grid sx={{display: user != null && user != false ? 'none' : 'flex', border: "solid 5px #0e2349", borderRadius: '15px', backgroundColor: "#c5ccc5", width: "25vw", height: "50vh", justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h3" gutterBottom sx={{ color: '#0e2349' }}>CONNEXION</Typography>
                <TextField onChange={(e => setMail(e.target.value))} id="outlined-basic" label="Mail" variant="outlined" />
                <TextField onChange={(e => setPassword(e.target.value))} id="outlined-basic" label="Mot de passe" variant="outlined" />
                <Grid>
                    <Button variant="contained" onClick={() => authenticateUser(mail,password)}>Connexion</Button>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: '#0e2349' }} onClick={() => window.location.href = 'Register'}>Toujours pas inscrit</Typography>
                </Grid>
            </Grid>
            <Grid sx={{display: (user != null && user != false) || user == 'wait' ? 'flex' : 'none', backgroundColor : "white"}}>
                <Typography>Connection effectuer avec succées</Typography>
            </Grid>
        </Grid>
    );
}

export default Login;
