import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useAuth } from './Register/Auth';
import { getAuth, signOut } from 'firebase/auth';


const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard'];

function MenuView({ children }) {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  
  const user = useAuth();

  const handleSignOut = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      // L'utilisateur est déconnecté avec succès
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const DrawerList = (
    <Box sx={{ width: 250, height:"100%", backgroundColor: "#030c1c", color: "white" }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {[{name : "Accueil", url : "/"}, {name : "Tier List", url : "/tierLists"}].map((data, index) => (
          <ListItem key={data.name} disablePadding>
            <ListItemButton  onClick={() => window.location.href = data.url}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon  sx={{color: "white"}} /> : <MailIcon  sx={{color: "white"}} />}
              </ListItemIcon>
              <ListItemText primary={data.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider sx={{backgroundColor : "#0e2349"}} />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon sx={{color: "white"}} /> : <MailIcon color='white' sx={{color: "white"}} />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );


  return (
    <Grid sx={{height : "100%"}}>
      <AppBar component="nav" sx={{backgroundColor:"#030c1c"}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <MenuIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}  onClick={toggleDrawer(true)} />


            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
             
            </Box>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              LOGO
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

            </Box>
            {user.user != null ? (
              <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
                <MenuItem onClick={handleSignOut}>
                  Deconnexion
                </MenuItem>
              </Menu>
            </Box>
            ): (
              <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Connect" onClick={() => window.location.href = '/Login'}>
                Connexion
              </Tooltip>
            </Box>
            )}
            

          </Toolbar>
        </Container>
      </AppBar>
      <Grid container sx={{ display: "flex", flexDirection: 'row' }}>
        <Grid sx={{ maxWidth: '10%' }}>
          <Drawer open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
          </Drawer>
        </Grid>
        <Grid sx={{ width: '100%', paddingTop: "7vh", minHeight:"100vh", backgroundColor: '#030c1c' }}>{children}</Grid></Grid>
    </Grid>
  );
}

export default MenuView;