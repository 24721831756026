import Papa from 'papaparse';
import { addDoc, collection, getFirestore } from 'firebase/firestore';

function InsertCsv() {
  const db = getFirestore();

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Lire le fichier CSV à l'aide de Papa.parse
            Papa.parse(file, {
                header: true,
                complete: async (results) => {
                    // Traiter les données
                    const data = results.data;

                    // Référence à la collection dans Firestore
                    const collectionRef = collection(db, 'TierItem');

                    // Insérer chaque ligne de données dans Firestore
                    for (const record of data) {
                        try {
                            await addDoc(collectionRef, record);
                            console.log('Données insérées avec succès:', record);
                        } catch (error) {
                            console.error('Erreur lors de l\'insertion des données:', error);
                        }
                    }
                },
            });
        }
    };

    return (
        <div>
            <input type="file" accept=".csv" onChange={handleFileChange} />
            {/* Ajoutez d'autres éléments de votre composant ici */}
        </div>
    );
}

export default InsertCsv;