import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const firebaseConfig = {
    apiKey: "AIzaSyAYLo7hrQgoATgbEKCn7dxAHvP9dElPXJ0",
    authDomain: "animetier-563ff.firebaseapp.com",
    projectId: "animetier-563ff",
    storageBucket: "animetier-563ff.appspot.com",
    messagingSenderId: "196586641001",
    appId: "1:196586641001:web:194b8b30de874115de34f8",
    measurementId: "G-9CY563QGJ9"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const registerUser = async (email, password) => {
    try {
        const auth = getAuth();
        onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                window.location.href = '/';
            }
        });
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log('Utilisateur inscrit avec succès:', user);
        // Enregistrer des données supplémentaires si nécessaire
    } catch (error) {
        console.error('Erreur lors de linscription:', error);
    }
};

function Register() {
    const [state, setState] = useState([]);
    const [mail, setMail] = useState();
    const [password, setPassword] = useState();
    const [formData, setFormData] = useState({
        password: '',
        mail: ''
    });

    // Gérer les changements dans le formulaire
    const handleChange = (event) => {
        const { name, value } = event.target;
        console.log(name)
        console.log(value)
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // URL de l'API à laquelle vous voulez envoyer les données
        const apiUrl = 'https://back.animetiers.fr/api/users/create';

        try {
            // Envoyer la requête POST
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json' // Spécifiez le type de contenu JSON
                },
                body: JSON.stringify(formData) // Convertir les données en format JSON
            });

            // Traiter la réponse
            if (response.ok) {
                const data = await response.json();
                console.log('Réponse reçue:', data);
                // Gérez les données reçues selon vos besoins
            } else {
                console.error('Erreur lors de la requête:', response.statusText);
            }
        } catch (error) {
            console.error('Erreur lors de la requête:', error);
        }
    };


    return (
        <Grid sx={{ display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center' }}>
            <Grid sx={{ border: "solid 5px #0e2349", borderRadius: '15px', backgroundColor: "#c5ccc5", width: "25vw", height: "50vh", display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'column' }}>
                <Typography variant="h3" gutterBottom sx={{ color: '#0e2349' }}>INSCRIPTION</Typography>
                <form onSubmit={handleSubmit}>
                <TextField onChange={handleChange} id="outlined-basic" name="mail" label="Mail" variant="outlined" />
                <TextField onChange={handleChange} id="outlined-basic" name="password" label="Mot de passe" variant="outlined" />
                <Grid>
                    <Button type="submit" variant="contained">Inscription</Button>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: '#0e2349' }} onClick={() => window.location.href = 'Login'}>Deja Inscrit</Typography>
                </Grid>
                </form>
            </Grid>
        </Grid>
    );
}

export default Register;
