import { Button, Grid, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../Register/Auth";
import Cardslist from "../../component/CardList/CardsList";

function Tierlists(params) {
    const user = useAuth();
    const [dataList, setDataList] = useState([])


    useEffect(() => {
        if (user?.dataTiers) {
            setDataList(user.dataTiers);
        }
    }, [user?.dataTiers]);

    return (
        <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid sx={{ height: '8vh', margin: '1vh 0', alignSelf: 'center' }}>
                <Button
                    variant="contained"
                    onClick={() => window.location.href = '/tierlist'}
                    sx={{ borderRadius: '24px', backgroundColor: '#31527a', height: '100%', alignContent: 'center', textAlign: 'center', width: '65vw', flexDirection: 'column' }}
                >
                    <AddIcon sx={{ color: 'white', fontSize: 'xxx-large' }} />
                    <Typography sx={{ color: 'white' }}>Créer une nouvelle TierList</Typography>
                </Button>
            </Grid>
            <Grid container sx={{ height: '80vh' }}>
                {dataList && dataList.length > 0 ? (
                    dataList.map((e, index) => (
                        // console.log(e)
                        <Cardslist data={e} index={index} />
                    ))
                ) : (
                    <Typography>Aucune donnée disponible</Typography>
                )}
            </Grid>
        </Grid>
    )
}

export default Tierlists;