import React from 'react';
import MenuView from './Menu';


function Layout({ children }) {
  return (
    <div style={{height:'100vh', backgroundColor: "#f7fbfb", overflowX : "hidden" }}>
      <MenuView children={children}/>
    </div>
  );
}

export default Layout;