// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-item{
    width: 4vw;
    height: 4vw;
    border: solid 1px;
}

.grid{
    min-height: 100px;
    max-height: 300px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/TierList/tierlist.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".grid-item{\n    width: 4vw;\n    height: 4vw;\n    border: solid 1px;\n}\n\n.grid{\n    min-height: 100px;\n    max-height: 300px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
