import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Layout from './Layout';
import Menu from './Menu';
import TierList from './component/TierList/TierList';
import Login from './Register/Login';
import Register from './Register/Register';
import Tierlists from './TierLists/Home/Tierlists';




function App() {
  return (
    <Router>
      <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tierList" element={<TierList/>} />
            <Route path="/tierLists" element={<Tierlists/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/register" element={<Register/>} />
          </Routes>
      </Layout>
    </Router>
  );
}

export default App;
