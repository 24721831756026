import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import QueueIcon from '@mui/icons-material/Queue';
import { deleteDoc, doc, getFirestore } from "firebase/firestore";
import { useState } from "react";

function Cardslist({ data, index }) {   
    const [view, setView] = useState(true)
    const db = getFirestore();
    const documentRef = doc(db, 'TierLists', data.id);

    async function supprimerDocument() {
        try {
            await deleteDoc(documentRef);
            setView(false);
            console.log('Document supprimé avec succès');
        } catch (error) {
            console.error('Erreur lors de la suppression du document:', error);
        }
    }
    

    return (
        <Grid
            key={data.id || index}
            sx={{
                display: view == true ? 'flex' : 'none' ,
                flexDirection: 'column',
                width: '20vh',
                height: '24vh',
                margin: '0 2vw',
                backgroundColor: '#9ea5a9',
                borderRadius: '7px',
            }}
        >
            <Grid sx={{cursor:'pointer'}} onClick={() => window.location.href = '/Tierlist?id='+data.id}>
            <Typography sx={{textAlign:'center', textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{data?.name != undefined ? data.name : data.id}</Typography>
            <Grid sx={{ width: '100%', height: '70%' }}>
                <img
                    src="http://fakeimg.pl/300/"
                    alt="Fake Image"
                    style={{
                        display: 'block',
                        margin: '0 auto',
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </Grid>
            </Grid>
            <ButtonGroup
                variant="text"
                color="info"
                sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30%', backgroundColor: 'grey' }}
            >
                {/* Utilisez des références distinctes pour chaque Player */}
                <Button sx={{ flexBasis: '33%' }}>
                    <FavoriteBorderIcon style={{ width: '70%', height: '70%', color:"#030c1c" }} />
                </Button>
                <Button sx={{ flexBasis: '33%' }}>
                    <DeleteOutlineIcon style={{ width: '70%', height: '70%', color:"#030c1c" }} onClick={() => supprimerDocument()}/>
                </Button>
                <Button sx={{ flexBasis: '33%' }}>
                    <QueueIcon style={{ width: '70%', height: '70%', color:"#030c1c" }} />
                </Button>
            </ButtonGroup>
        </Grid>
    );
}

export default Cardslist;
