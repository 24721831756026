import { useState, createContext, useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';

// Créer un contexte d'authentification
const AuthContext = createContext();

// Fonction pour récupérer le contexte d'authentification
export function useAuth() {
  return useContext(AuthContext);
}

// Composant qui fournit le contexte d'authentification à toute l'application
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userDataRef, setUserDataRef] = useState(null);
  const [dataTiers, setDataTiers] = useState(null);


  // Écouter les changements d'état de l'authentification
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        // L'utilisateur est connecté
        setUser(authUser);
        
        // Récupérer les données utilisateur à partir de Firestore
        const db = getFirestore();
        const userDocRef = doc(db, 'users', authUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
          setUserDataRef(userDocRef);
          setDataTiers(await fetchDocumentsRelatedToUser(authUser?.uid))
        } else {
          console.log('Aucune donnée utilisateur trouvée.');
        }
      } else {
        // L'utilisateur n'est pas connecté
        setUser(null);
        setUserData(null);
        setUserDataRef(null);
      }
    });

    return () => unsubscribe();
  }, []);

  async function fetchDocumentsRelatedToUser(userId) {
    try {
        const db = getFirestore();
        // Référence à la collection que vous souhaitez interroger
        const collectionRef = collection(db, 'TierLists');
        // Créez une requête pour filtrer les documents en fonction de l'ID de l'utilisateur
        const userQuery = query(collectionRef, where('userId', '==', doc(db, 'users', userId)));
        // Exécutez la requête
        const querySnapshot = await getDocs(userQuery);
        // Parcourez les résultats et stockez les documents liés
        const relatedDocuments = [];
        querySnapshot.forEach((doc) => {
            relatedDocuments.push({ id: doc.id, ...doc.data() });
        });

        console.log('Related documents:', relatedDocuments);

        // Retournez les documents liés
        return relatedDocuments;
    } catch (error) {
        console.error('Error fetching related documents:', error);
        return null;
    }
}

  return (
    <AuthContext.Provider value={{ user, userData, userDataRef, dataTiers }}>
      {children}
    </AuthContext.Provider>
  );
}