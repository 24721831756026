import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, addDoc, query, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { TextField } from "@mui/material";
import { useAuth } from "../../Register/Auth";

const firebaseConfig = {
  apiKey: "AIzaSyAYLo7hrQgoATgbEKCn7dxAHvP9dElPXJ0",
  authDomain: "animetier-563ff.firebaseapp.com",
  projectId: "animetier-563ff",
  storageBucket: "animetier-563ff.appspot.com",
  messagingSenderId: "196586641001",
  appId: "1:196586641001:web:194b8b30de874115de34f8",
  measurementId: "G-9CY563QGJ9"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);



// Permet de créer des cartes a placer 
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`
  }));

//Permet de réordonner les données afins d'avoir une belle liste
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list.content);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source.content);
  const destClone = Array.from(destination.content);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  display: 'flex',
  padding: grid * 2,
  margin: `0 0 ${grid}px ${grid}px`,
  height: 50,
  width: 50,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});
const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#071123",
  padding: grid,
  minHeight: 100,
  width: "85%",
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
  border: "solid 2px #0e2349",
  borderRadius: '5px'
});
const getListStyleGlobal = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "#071123",
  padding: grid,
  minHeight: 100,
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
  border: "solid 2px #0e2349",
  borderRadius: '5px'
});



function Board() {
  const [data, setData] = useState([]);
  const [state, setState] = useState([]);
  const [name, setName] = useState('');
  const [documentRefId, setdocumentRefId] = useState([]);
  const [idTierList, setIdTierList] = useState("");
  const user = useAuth();

  const fetchDataItems = async (data) => {
    // On récupère les datas à placer dans la tierList
    const querySnapshot = await getDocs(collection(db, 'TierItem'));
    const documents = [];
    const allIds = [];
    data.forEach((item) => {
      item.content.forEach((contentItem) => {
        allIds.push(contentItem.id);
      });
    });

    querySnapshot.forEach((doc) => {
      if (!allIds.includes(doc.id)) {
        documents.push({ id: doc.id, ...doc.data() });
      }
    });

    // Mettre à jour seulement le tableau correspondant à "Liste" dans l'état
    setState(prevState => {
      return prevState.map(item => {
        if (item.name === "Liste") {
          return { ...item, content: documents };
        }
        return item;
      });
    });

  }


  useEffect(() => {

    const fetchData = async () => {
      //On recupere ou on crée une TierList 
      const urlParams = new URLSearchParams(window.location.search);
      const idlist = urlParams.get('id')
      setIdTierList(idlist);
      if (idlist) {
        try {
          // Référence à la collection spécifiée par son nom
          const documentRef = doc(db, 'TierLists', idlist);
          // Récupérer tous les documents de la collection
          const documentSnapshot = await getDoc(documentRef);

          // Parcourir les documents (si nécessaire)
          if (documentSnapshot.exists()) {
            console.log('Document data:', documentSnapshot.data());
            const data = documentSnapshot.data();

            // Copiez l'état actuel
            const updatedState = [...state];

            // Parcourez les données que vous avez reçues
            Object.keys(data).forEach(key => {
              // Trouvez l'objet correspondant dans l'état
              const existingItem = updatedState.find(item => item.name === key);
              // Si l'objet existe, ajoutez les données à son tableau content
              console.log('data.name')
              console.log(data.name)
              setName(data.name)
              if (existingItem) {
                existingItem.content = data[key];
              }
              data['Liste'] = []

            });

            const finalData = [
              { id: 0, name: "S", content: [] },
              { id: 1, name: "A", content: [] },
              { id: 2, name: "B", content: [] },
              { id: 3, name: "C", content: [] },
              { id: 4, name: "D", content: [] },
              { id: 5, name: "E", content: [] },
              { id: 6, name: "F", content: [] },
              { id: 7, name: "Liste", content: [] }
            ];

            // Mettez à jour l'état avec les tableaux remplis
            for (const key in data) {
              if (key !== "userId" && key !== "Liste") {
                // Récupérer le tableau content correspondant à la clé
                const contentArray = finalData.find(item => item.name === key)?.content;

                // Vérifier si le tableau content existe et si la valeur de la clé est un tableau
                if (contentArray && Array.isArray(data[key])) {
                  // Ajouter les éléments au tableau content
                  contentArray.push(...data[key]);
                }
              }
            }
            setState(finalData);
            fetchDataItems(finalData)
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error getting collection:', error);
        }
      } else if (user.userDataRef) {
        try {
          const dataNew = {
            userId: user.userDataRef,
            S: [],
            A: [],
            B: [],
            C: [],
            D: [],
            E: [],
            F: [],
            name: 'Sans titre',
            fav: false,
          }

          // Ajouter un nouveau document à une collection 'users'
          const docRef = await addDoc(collection(db, 'TierLists'), dataNew);
          console.log('Document written with ID: ', docRef.id);
          // Réinitialiser le formulaire après la création du document
          window.location.href = '/TierList?id=' + docRef.id
        } catch (error) {
          console.error('Error adding document: ', error);
        }
      }
    };

    if (user !== null && user.user !== null && state.length === 0) {
      fetchData();
    }

  }, [db, user]);

  const changeData = async (source, destination) => {
    const documentId = `${idTierList}`; // Remplacez 'votre_id_de_document' par l'ID du document que vous souhaitez mettre à jour
    const documentRef = doc(db, 'TierLists', documentId); // Remplacez 'votre_collection' par le nom de votre collection
    // Définir les données à mettre à jour

    const newData = source.name == "Liste" ? (
      {
        [destination.name]: destination.content,
      }
    ) : (
      {
        [source.name]: source.content,
        [destination.name]: destination.content,
      }
    )

    try {
      // Mettre à jour le document avec les nouvelles données
      await updateDoc(documentRef, newData);
      console.log('Document updated successfully');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  }

  async function renameTierList(e) {
    // console.log(e.value)
    const documentId = `${idTierList}`; // Remplacez 'votre_id_de_document' par l'ID du document que vous souhaitez mettre à jour
    const documentRef = doc(db, 'TierLists', documentId); // Remplacez 'votre_collection' par le nom de votre collection
    // Définir les données à mettre à jour
    setName(e.target.value)
    const newData = { name: e.target.value }

    try {
      // Mettre à jour le document avec les nouvelles données
      console.log('newData')
      console.log(newData)
      await updateDoc(documentRef, newData);
      console.log('Document updated successfully');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  }

  async function onDragEnd(result) {
    const { source, destination } = result;

    // Si je sort l'item de l'ensemble des listes
    if (!destination) {
      return;
    }
    //on set l'id de source et de destination
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    //Si on a pas modifier l'emplacement de notre carte dans une autre liste alors on modifie son emplacement et on réorder
    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = { id: sInd, name: state[sInd].name, content: items };
      setState(newState);
    } else {
      const sContent = state[sInd];
      const dContent = state[dInd];
      const result = move(sContent, dContent, source, destination);
      const newState = [...state];
      newState[sInd].content = result[sInd];
      newState[dInd].content = result[dInd];
      // setState(newState);
      changeData(newState[sInd], newState[dInd]);
    }
  }

  async function deleteId(id) {
    const data = state

    const datafilter = data.map(objet => {
      // Si l'ID est présent dans le content de l'objet
      if (objet.content.find(element => element.id === id)) {
        // Filtrer les éléments dont l'ID ne correspond pas à celui donné
        const nouveauContent = objet.content.filter(element => element.id !== id);
        // Retourner un nouvel objet avec le nouveau content
        return { ...objet, content: nouveauContent };
      } else {
        // Si l'ID n'est pas présent dans le content, retourner simplement l'objet tel quel
        return objet;
      }
    });
    setState(datafilter)

    const filteredArray = datafilter.filter(item => item.name !== "Liste");
    fetchDataItems(filteredArray)
    const newData = {
      S: filteredArray.find(item => item.name === "S").content,
      A: filteredArray.find(item => item.name === "A").content,
      B: filteredArray.find(item => item.name === "B").content,
      C: filteredArray.find(item => item.name === "C").content,
      D: filteredArray.find(item => item.name === "D").content,
      E: filteredArray.find(item => item.name === "E").content,
      F: filteredArray.find(item => item.name === "F").content,
    };
    try {
      // Mettre à jour le document avec les nouvelles données
      const documentId = `${idTierList}`;
      const documentRef = doc(db, 'TierLists', documentId);
      await updateDoc(documentRef, newData);
      console.log("Document updated successfully");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }


  return (

    <Grid style={{ display: "flex", justifyContent: "space-evenly", width: '100%' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid style={{ display: "flow", width: '75%' }}>
          <Grid container sx={{ width: "100%", alignContent: 'space-evenly', textAlign: 'center', flexDirection: 'column' }}>
            <Typography variant="h5" sx={{ color: "white" }}>Nom de votre TierList</Typography>

            <TextField
              sx={{
                // Personnalisez le style du TextField
                '& .MuiInputBase-root': {
                  color: 'white', 
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white', 
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                  },
                },
              }}
              value={name}
              onChange={(e) => renameTierList(e)}></TextField>
          </Grid>
          {state.filter(e => e.name != "Liste").map((e) => (
            <Droppable key={e.id} droppableId={`${e.id}`} direction="horizontal">
              {(provided, snapshot) => (
                <Grid container sx={{ marginTop: "10px" }}>
                  {/* Section Gauche pour mettre un nom sur chaque ligne */}
                  <Grid sx={{ width: "15%", alignContent: 'space-evenly', textAlign: 'center' }}><Typography variant="h3" sx={{ color: "white" }}>{e.name}</Typography></Grid>
                  {/* Section ou on va pouvoir placer chacune des datas  */}
                  <Grid
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {e.content.map((item, ind) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={ind}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around"
                              }}
                            >
                              {item.Name}
                              <Button variant="text"
                                sx={{ width: '10%', minWidth: 'unset', height: "10%" }}
                                type="button"
                                onClick={() => { deleteId(item.id) }}
                              >
                                <DeleteIcon sx={{ color: 'white' }} />
                              </Button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                </Grid>
              )}
            </Droppable>
          ))}
        </Grid>
        <Grid style={{ display: "flow", width: '20%' }}>
          {state.filter(e => e.name == "Liste").map((e) => (
            <Droppable key={e.id} droppableId={`${e.id}`}>
              {(provided, snapshot) => (
                <Grid container>
                  <Grid sx={{ width: "100%", alignContent: 'space-evenly', textAlign: 'center' }}><Typography variant="h3" sx={{ color: "white" }}>{e.name}</Typography></Grid>
                  <Grid
                    ref={provided.innerRef}
                    style={getListStyleGlobal(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >

                    {e.content.map((item, ind) => (
                      ind < 30 ? (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={ind}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around"
                              }}
                            >
                              {item.Name}
                            </div>
                          </div>
                        )}
                      </Draggable>
                      ) : null
                    ))}
                    {provided.placeholder}
                  </Grid>
                </Grid>
              )}
            </Droppable>
          ))}
        </Grid>
      </DragDropContext>
    </Grid>
  );
}

export default Board;
