import logo from './logo.svg';
import './App.css';
import SimpleSlider from './component/Carrousel/SimpleSlider';
import Lists from './component/Lists/Lists';
import { Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import Papa from 'papaparse';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import InsertCsv from './component/InsertCsv';
import axios from 'axios';

function Home() {



  return (
    <Grid className="App">
      <SimpleSlider links={['test', 'test2', 'test3']}></SimpleSlider>
      <Grid sx={{ display: "flex", flexDirection: 'column', alignItems: 'center' }} >
        <Lists title={'Les TierLists les plus récentes'} width={"90vw"}></Lists>
        <Lists title={'Vos TierLists'} type="account" width={"90vw"}></Lists>
        {/* <InsertCsv/> */}
        
      </Grid>
    </Grid>
  );
}

export default Home;
