
import React, { useRef, useEffect } from 'react'
import '../../css/TierList/tierlist.css';
import Board from './Board';
import Grid from '@mui/material/Grid';

function TierList() {


  return (
    <Grid container sx={{ justifyContent:'center', height:'100%'}}>
      <Board/>
    </Grid>
  );
}


export default TierList;
