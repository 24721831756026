
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useAuth } from "../../Register/Auth";
import { collection, doc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import Cardslist from "../CardList/CardsList";

export default function Lists({ type, width, title }) {
    const user = useAuth();
    const [dataTiers, setDataTiers] = useState(null);


    useEffect(() => {
        setDataTiers(user?.dataTiers)
    }, [user?.dataTiers != null && type == 'account']);


    return (
        <Grid sx={{ width: width ? width : "100%", height: '28vw', display: "flex", justifyContent: 'space-evenly', flexDirection: 'column', margin: "5vh 0", backgroundColor: '#071c42', boxShadow: "0 0 16px 16px #071c42", borderRadius: "15px" }}>
            <Typography variant="h4" sx={{ color: "white", textAlign: 'left', margin: '0 2vw' }}>{title}</Typography>
            <Grid sx={{ width: "100%", height: '21vw', overflowX: "scroll" }}>
                <Grid sx={{ width: 'fit-content', overflowY: "hidden", flexDirection: "row", display: 'flex', height: '100%' }}>
                    {type == 'hot' ? (
                        <Grid></Grid>
                    ) : (type == 'account') ? (
                        dataTiers == null ? (
                            <CircularProgress />
                        ) : dataTiers != [] ? (
                            dataTiers.map((e, index) => (
                                <Cardslist data={e} index={index} />
                            ))
                        ) : (
                            <Grid onClick={() => window.location.href = "/TierList"} sx={{ width: "100%", height: "10vw", color: "white", textAlign: 'left', alignContent: 'center', cursor: "pointer" }}>
                                <Typography variant="h5">Vous n'avez toujours pas effectué de TierList</Typography>
                                <Typography variant="subtitle1">Cliquez ici pour commencer</Typography>
                            </Grid>
                        )

                    ) : (
                        ["1", "2", "3", "4", "5", "6", "6", "6"].map(e => (
                            <Grid sx={{ width: "20vw", height: "10vw", backgroundColor: 'cyan', alignSelf: 'center', margin: '0 1vw' }}><p>{e}</p></Grid>
                        ))
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}