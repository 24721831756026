import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Typography } from "@mui/material";

export default function SimpleSlider({links}) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    fade: true,
    autoplaySpeed: 2000,
  };
  return (
    <Slider {...settings}>
      {links.map(e => (
        <Grid sx={{ height:'40vw', backgroundColor:'white', justifyContent:'center', alignItems:'center', display:'flex !important'}}>
          <Typography variant="h1">{e}</Typography>
        </Grid>
      ))}
    </Slider>
  );
}